@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@500&display=swap');


 /* cario fonts  */
 @font-face {
    font-family: "Cairo-Black";   /*Can be any text*/
    src: local("Cario"),
      url("./assets/fonts/Cairo/Cairo-Black.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Cairo-Bold";   /*Can be any text*/
    src: local("Cario"),
      url("./assets/fonts/Cairo/Cairo-Bold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Cairo-ExtraLight";   /*Can be any text*/
    src: local("Cario"),
      url("./assets/fonts/Cairo/Cairo-ExtraLight.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Cairo-Light";   /*Can be any text*/
    src: local("Cario"),
      url("./assets/fonts/Cairo/Cairo-Light.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Cairo";   /*Can be any text*/
    src: local("Cario"),
      url("./assets/fonts/Cairo/Cairo-Regular.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Cairo-SemiBold";   /*Can be any text*/
    src: local("Cario"),
      url("./assets/fonts/Cairo/Cairo-SemiBold.ttf") format("truetype");
  }


  

* {
 font-family: 'Cairo', sans-serif;
 -ms-overflow-style: none;
}
/* ::-webkit-scrollbar {
    display: none;
} */
#root {
    background-color: #edeFFF ;
}
