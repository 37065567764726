@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');


body{
  font-family: "Cairo";
}
#login_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.dx-datagrid .dx-data-row > td.centered {
  text-align: center;
}
/* dx-scheduler */
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active, .dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll, .dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover, .dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover .dx-scrollable-scroll {
  height: 40px;
}
.dx-scrollbar-horizontal.dx-scrollbar-hoverable .dx-scrollable-scroll.dx-state-invisible .dx-scrollable-scroll-content {
  height: 40px;
}
.dx-scrollable-scroll
{
  height: 40px;
}


/* standard scroll */

/* .dx-scheduler-cell-sizes-vertical {
  overflow: auto;
} */
.dx-scheduler-group-row {
  background-color: #2e6b95;
}
/* .dx-scheduler-group-row .dx-scheduler-group-header .dx-scheduler-group-header-content{
  width: 100px;
}
 .dx-scheduler-cell-sizes-horizontal .dx-scheduler-cell-sizes-vertical .dx-scheduler-date-table-cell{
  width: 100px;
} */

/* 
.dx-scheduler-all-day-table-row::before, .dx-scheduler-group-row::before, .dx-scheduler-header-row::before{
  background-color: #ffffff;
  
}
.dx-scheduler-all-day-panel {
  display: none;
  }
.dx-scheduler-header-panel{
  width: auto;
  margin-left: 100px;
}
.dx-scheduler-group-header-content{
  padding: 5px;
  margin: auto;
  color: white;
  font-family: "Cairo";
  text-align: center;
  word-wrap: break-word;
}

.dx-scheduler-group-header{
  min-width:150px;
  padding: 5px;
}
.dx-scheduler-group-flex-container .dx-scheduler-group-header, .dx-scheduler-header-panel .dx-scheduler-group-header, .dx-scheduler-work-space-vertical-group-table .dx-scheduler-group-header{
  min-width:150px;
  padding: 5px;
}
.dx-scheduler-date-table-row td{
  min-width: 150px;
}
.dx-scheduler-first-group-cell .dx-scheduler-last-group-cell .dx-scheduler-cell-sizes-horizontal .dx-scheduler-cell-sizes-vertical .dx-scheduler-date-table-cell{
  min-width: 150px;
}

.dx-scheduler-group-header-content div{
  white-space: pre-line;
  width: 100%;
  font-family: "Cairo"

} */

/* end standard scroll */


/* .dx-scheduler-cell-sizes-vertical {
  overflow: auto;
} */
.dx-scheduler-group-row {
  background-color: #2e6b95;
}
/* .dx-scheduler-group-row .dx-scheduler-group-header .dx-scheduler-group-header-content{
  width: 100px;
}
 .dx-scheduler-cell-sizes-horizontal .dx-scheduler-cell-sizes-vertical .dx-scheduler-date-table-cell{
  width: 100px;
} */

 .dx-scheduler-cell-sizes-horizontal {
  width: 150px!important;  
}
.dx-scheduler-cell-sizes-vertical {
  height: 50px;
}
.dx-scheduler-all-day-table-row::before, .dx-scheduler-group-row::before, .dx-scheduler-header-row::before{
  background-color: #ffffff;
  
}
.dx-scheduler-all-day-panel {
  display: none;
  }
.dx-scheduler-group-header-content{
  padding: 5px;
  margin: auto;
  color: white;
  font-family: "Cairo";
  text-align: center;
  word-wrap: break-word;
}

/* .dx-scheduler-group-header{
  min-width:150px;
  padding: 5px;
}
.dx-scheduler-group-flex-container .dx-scheduler-group-header, .dx-scheduler-header-panel .dx-scheduler-group-header, .dx-scheduler-work-space-vertical-group-table .dx-scheduler-group-header{
  min-width:150px;
  padding: 5px;
}
.dx-scheduler-date-table-row td{
  min-width: 150px;
}
.dx-scheduler-first-group-cell .dx-scheduler-last-group-cell .dx-scheduler-cell-sizes-horizontal .dx-scheduler-cell-sizes-vertical .dx-scheduler-date-table-cell{
  min-width: 150px;
} */

.dx-scheduler-group-header-content div{
  white-space: pre-line;
  width: 100%;
  font-family: "Cairo"

}
.dx-scheduler-group-header-content div::first-line {
  color: #fffb00;
  
  /* font-weight: 100; */
  /* text-s
  hadow: 0 0 2px rgb(255, 255, 255); horizontal-offset vertical-offset 'blur' colour */
}
.dx-scheduler-appointment-content{
  background-color: white;
  max-width: 100vw;
  min-width: 10vw;
}
.dx-scheduler-appointment{
  background-color: white;
  max-width: 100vw;
  min-width: 10vw;
  text-align: center;
}

.dx-scheduler-work-space-grouped:not(.dx-scheduler-work-space-all-day):not(.dx-scheduler-timeline):not(.dx-scheduler-agenda):not(.dx-scheduler-work-space-vertical-grouped):not(.dx-scheduler-work-space-horizontal-grouped).dx-scheduler-work-space-day:not(.dx-scheduler-work-space-count)[dx-group-row-count="1"] .dx-scheduler-header-scrollable{
  margin-top: -9px;
  height: auto;
}

/*
css for appointment tooltip view
*/
.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  padding: 14px;
}

.appointment-preview > div:first-child {
  font-size: 12px;
  white-space: normal;
}

.appointment-preview > div:not(:first-child) {
  font-size: 11px;
  white-space: normal;
}

.rdrDateRangePickerWrapper{
  width: 100% ;
}



.mission-tooltip{

  padding: 5px;
  width: 100%;
  max-width: 100vw;
  min-width:10vw;

  height: 100%;
  font-size: 24px;
  text-align: center;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}

.mission-tooltip .mission-info {
  display: inline-block;
  margin: auto;
  vertical-align: top;
  text-align: center;
  width: 100%;
  height: 100%;
}

.mission-tooltip img {
  height: 80px;
  margin-bottom: 10px;
}

.mission-tooltip .mission-title {
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .01em;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  white-space: pre-line;


  font-family: 'Cairo';
  /* white-space: nowrap;  */
  overflow: hidden;
  width: 100%;
  /* font-size: 12px; */
  /* opacity: .8; */
  /* text-overflow: ellipsis;  */
  

  /* font-weight: 200;
  font-size: 14px; */
  /* font-size: 3.5vw; */
  text-align: center;
  margin: auto;
  color:white;
}

.long-title  {

  
  font-family: 'Cairo';
  white-space: nowrap; 
  overflow: hidden;
  width: 100%;
  font-size: 12px;
  opacity: .8;
  text-overflow: ellipsis; 
  

  font-weight: 200;
  /* font-size: 3.5vw; */
  text-align: center;
  margin: auto;
  color:white;

   
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

 /* .dx-scrollable-scroll {  
  height: 20px !important;  
}  
 .dx-scrollable-scroll-content {  
  background-color:green !important;  
  height: 100px;
} */
#main {
  /* background-image: url(../img/22.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 600px; */
  width: 100%;
}
.MuiExpansionPanel-root.Mui-expanded {
  margin:0px;
}
#icons_headerTask {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
td {
  border-right: solid 1px #e7e8e8;
  border-left: solid 1px #e7e8e8;
}

#date_btn1,
#date_btn2,
#date_btn3 {
  cursor: pointer;
  height: 43px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #e5e9f2;
  border-radius: 5px;
  color: #828282;
}
#date_btn {
  cursor: pointer;
  height: 43px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(106, 170, 104);
  border: 1px solid #e5e9f2;
  border-radius: 5px;
  color: #fff;
}

.MuiAppBar-root {
  z-index: 2;
}
#date_btn:active {
  transform: scale(1.2);
}
#date_btn1:active {
  transform: scale(1.2);
}
#date_btn2:active {
  transform: scale(1.2);
}
#date_btn3:active {
  transform: scale(1.2);
}
#dash_filter {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: 96%;
  justify-content: flex-end;
}
#parent {
  position: relative;
  text-align: end;
  min-width: 60%;
  color: #2e6b95;
}
#parent:hover #hover-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 5;
  padding: 4px;
  border-radius: 5px;
}
#hover-content {
  display: none;
  padding: 4px;
  min-width: 100%;
  overflow: auto;
  /* height: 160px; */
  background-color: #dfe0e0;
}

#OthersParent {
  position: relative;
}
#OthersContent {
  display: none;
  padding: 4px;
  min-width: 100%;
  overflow: auto;
  /* height: 160px; */
}
.dep_icons {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#jj{
  min-height: calc(100vh - 50px);
}
#foot{
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconUserDialog {
  width: 55px;
  text-align: center;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom, #ffffff, #f4f5f7);
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14),
    inset 0 -1px 1px 0 rgba(67, 90, 111, 0.06);
    cursor: pointer;
}
.dropdown-menu{
 z-index: 10;
}
.dropdown-menu.show{
  transform: translate(12.25px, 38.75px);
  
}
.dropdown-menu[x-placement^=bottom]{
  transform: translate(12.25px, 38.75px); 
  width: 50px;
}
#OthersParent:hover #OthersContent {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 5;
  padding: 4px;
  border-radius: 5px;
  background-color: #fff;
  width: 200px;
  padding: 4px;
  color: #2e6b95;
}
#circle1 {
  display: flex;
  align-items: center;
  width: 33.33%;
}
#left1 {
  justify-content: flex-end;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  width: 33.33%;
  padding-left: 15px;
  margin-bottom: 30px;
}
#dateEditUser{
  width: 310px;
}
#dateEditUser1{
  width: 295px;
}
#info_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c32929d9;
  width: 40%;
  height: 30px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-family: "Cairo";
  font-weight: 500;
}
#info_btn:active {
  transform: scale(1.09);
}
#rej_btn {
  background-color: #d84e48;
  color: #fff;
  border-radius: 3px;
  width: 60px;
  text-align: center;
}
#accp_btn {
  background-color: #76b57d;
  color: #fff;
  border-radius: 3px;
  width: 60px;
  text-align: center;
}
#deadTime{
  cursor: pointer;
    color: rgb(130, 130, 130);
    /* background-color: rgb(255, 255, 255); */
    background-image: linear-gradient(rgb(255, 255, 255), rgb(244, 245, 247));
    box-shadow: rgba(67, 90, 111, 0.14) 0px 0px 0px 1px inset, rgba(67, 90, 111, 0.06) 0px -1px 1px 0px inset;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
#info_btnMain {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
#tit_task {
  background-color: #2e6b95;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 2px;
  padding: 3px;
}
.Sign_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #bfe3ea73;
  width: 30%;
  height: 200px;
  border-radius: 5px;
  padding: 30px 60px 26px;
  box-shadow: 0px 0px 3px #ada9a9;
}
.up_field {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#field1 {
  width: 80%;
  /* height: 25px; */
  border-radius: 5px;
  outline: none;
  border: 1px solid #ababab;
  padding: 5px;
  margin-bottom: 10px;
}

#notification_main {
  background-color: #808181;
  min-height: 100px;
  width: 100%;
  margin-bottom: 40px;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}
#noti_btn_dwn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#notiTitle{
  padding-left: 25px;
    font-size: 20px;
    font-family: "Cairo";
    padding-bottom: 10px;
}
#clear_noti {
  font-size: 22px;
  font-family: "Cairo";
  margin-bottom: 30px;
  color: #d9251d;
  cursor: pointer;
  width: 200px;
}

#type_btn {
  position: absolute;
  min-width: 90px;
  text-align: center;
  background-color: #eeb821;
  border-radius: 2px;
  top: -14px;
  font-size: 14px;
  font-family: "Cairo";
  padding-left: 5px;
}
#type_btn1 {
  /* position: absolute; */
  width: 300px;
  text-align: center;
  background-color: #eeb821;
  border-radius: 2px;
  top: -14px;
  font-size: 14px;
  font-family: "Cairo";
  padding-left: 5px;
  
}
#Not_notic{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: "Cairo";
  font-weight: 600;
  color: #22619f;
  height: 50px;
}
#noti_text {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding-left: 25px;
  font-size: 16px;
  font-family: "Cairo";
}
#span_noti1 {
  padding-right: 10px;
}
#span_noti2 {
  padding-left: 10px;
}
#noti_date {
  display: flex;
  width: 100%;
  /* height: 10px; */
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}
#field12 {
  width: 80%;
  /* height: 25px; */
  border-radius: 5px;
  outline: none;
  border: 1px solid #ababab;
  padding: 5px;
  margin-bottom: 10px;
}
#field2 {
  width: 80%;
  height: 40px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ababab;
  padding: 5px;
  margin-bottom: 10px;
}
#dialog_title {
  width: 30%;
  text-align: left;
  color: #517c92;
  font-size: 15px;
  font-family: "Cairo";
  padding-left: 2px;
}
#del {
  color: #d94848;
  font-size: 18px;
  font-family: "Cairo";
}
#dels {
  color: #d94848;
  font-size: 20px;
  font-family: "Cairo";
}
#edit {
  color: #22619f;
  font-size: 18px;
  font-family: "Cairo";
}

.react-datepicker__input-container input {
  display: block;

  /* width: 350px; */
  height: 40px;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #ababab;
}
#edit:hover,
#del:hover {
  cursor: pointer;
  transform: scale(1.1);
}
#dels:hover {
  cursor: pointer;
  transform: scale(1.1);
}
#sign_but {
  width: 80%;
  /* height: 25px; */
  border-radius: 5px;
  outline: none;
  border: 1px solid #ababab;
  padding: 5px;
  margin-bottom: 10px;
  text-align: center;
  background-color: #ba2c22;
  color: #fff;
}
#sign_but:hover {
  transition-duration: 0.3s;
  transition-timing-function: linear;
  color: #ba2c22;
  background-color: #fff;
  border: 1px solid #ba2c22;
  cursor: pointer;
}
#sign_but:active {
  transform: scale(1.03);
  background-color: #ba2c22;
  color: #fff;
}

#field3 {
  width: 95%;
  min-height: 80px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ababab;
  padding: 5px;
  margin-bottom: 10px;
}
.btn_lang {
  text-align: center;
  color: #828282;
  border: none;
  background-color: #f6f4f4;
}

.filter_span {
  color: gray;
  font-weight: 700;
  font-size: 18px;
  font-family: "Cairo";
}
.MuiFab-primary:hover {
  background-color: red;
}
#tasknew {
  cursor: pointer;
  font-size: 20px;
  color: #828282;
  font-weight: 500;
  font-family: "Cairo";
  width: 20%;
}
.task_type_style {
  width: 100%;
  background-color: #dfe0e0;
  /* border-radius: 3px; */
  /* box-shadow: 1px 1px 0px 0px #c7c7c7; */
  text-align: center;
  color: #5f5c6d;
  /* font-size: 17px; */
  font-weight: 700;
  display: flex;
  font-family: "Cairo";
  align-items: center;
  justify-content: center;
}
/* .task_priority{
  width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
    background-color: #C1A294;
} */
#head_task {
  display: flex;
}
button {
  outline: none;
}
.down_field {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
#sarnavimg44 {
  display: flex;
  height: 50px;
  width: 100%;
}
#nav_title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88%;
}
#navmain {
  display: flex;
  background-color: #2e6b95;
}
.logo-large {
  display: flex;
  align-items: center;
  justify-content: center;
}
.active .sspan {
  color: #8a0b07;
}

.sspan {
  color: #3b4856;
  padding-left: 20px;
}

a:hover {
  text-decoration: none;
}
a {
  text-decoration: none;
}
#side_img {
  height: 25px;
}

.active .sidefect {
  background-color: #8282826b;
}
.attach_icon {
  margin-right: 10px;
  background-image: linear-gradient(to bottom, #ffffff, #f4f5f7);
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14),
    inset 0 -1px 1px 0 rgba(67, 90, 111, 0.06);
  color: #425a70;
  background-color: white;
  height: 32px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.attach_icon:hover {
  background-image: none;
  background-color: rgba(16, 112, 202, 0.09);
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14),
    inset 0 1px 1px 0 rgba(67, 90, 111, 0.06);
}
.attach_icon:active {
  background-image: none;
  background-color: rgba(16, 112, 202, 0.09);
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14),
    inset 0 1px 1px 0 rgba(67, 90, 111, 0.06);
  transform: scale(1.05);
}
.file_show {
  /* margin-top: 20px; */
  color: #61adc3;
  font-size: 17px;
  font-weight: 600;
  font-family: "Cairo";
  display: flex;
  align-items: center;
  cursor: pointer;
}
.location {
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Cairo";
  color: #856758;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  cursor: grab;
  padding-bottom: 20px;
}
.icon_file_head {
  font-size: 35px;
  font-family: "Cairo";
  transform: rotate(45deg);
  color: gray;
}
.weight {
  width: 10%;
  background: linear-gradient(58deg, #f6f4f4 80%, rgb(223, 224, 224) 0);
  display: flex;
  align-items: center;
  color: #d9251e;
  font-weight: 700;
  font-family: "Cairo";
  padding-left: 15px;
}
#main_sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 100%; */
}
#main_row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-top: 2%; */
  margin-bottom: 2%;
}
#main_row a {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#new {
  /* border: 1px solid #fdf0f0; */
  border-radius: 10px;
  /* box-shadow: 0 0 5px 1px #c2c2c2; */
  height: 40px;
  background-color: lightgray;
  color: #041929;
  margin-top: 3%;
  font-size: 18px;
  font-weight: 600;
  font-family: "Cairo";
  display: flex;
  align-items: center;
  justify-content: center;
}
#new:hover {
  cursor: pointer;
  border: 2px solid #fff;
  background-color: #041929;
  color: #fff;
  transform: scale(1);
  transition-duration: 0.3s;
  transition-timing-function: linear;
  /* box-shadow: 0 0 5px 1px #ede9e9d1; */
}
#new:active {
  border: none;
  background-color: #a7221c;
  color: #fff;
  transform: scale(1);
  transition-duration: 0.2s;
  transition-timing-function: linear;
}
#cuthome {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#dash_row {
  margin-right: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}
#work_log {
  width: 70%;
  padding-left: 5px;
  margin-left: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid gray;
  outline: none;
  direction: rtl;
}

#coment {
  width: 70%;
  padding-left: 5px;
  margin-left: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid gray;
  outline: none;
  direction: rtl;
}
#com_icon {
  color: #22619f;
  cursor: pointer;
}
#com_icon:active {
  transform: scale(1.2);
}
#pan_main {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 20px;
}

.comment_item{
  margin-top: 5px;
  width: 100%;
  padding: 0 30px 0 30px;
  background-color: #f6f4f4;
   margin-bottom: 5px;
}

.work_log_item{
  margin-top: 5px;
  width: 100%;
  padding: 0 30px 0 30px;
  background-color: #f6f4f4;
   margin-bottom: 5px;
}
.card {
  border: none;
  box-shadow: 4px 4px 0px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  position: relative;
  padding: 10px;
}
.card-body {
  flex: 1 1 auto;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 0px 0px 11px #cde4da;
}
.numcard {
  font-size: 20px;
  font-weight: 600;
  font-family: "Cairo";
  color: #2e6b95;
  padding-top: 10px;
}
.mt-0,
.mb-0 {
  margin-top: 0 !important;
  /* color: #57626d; */
  color: #2e6b95;
  font-size: 20px;
  font-weight: 600;
  font-family: "Cairo";
  padding-top: 10px;
  text-align: center;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fafafa;
  background-color: #828282;
  border-color: #dee2e6 #dee2e6 #fff;
}
#coment1 {
  background: none;
  border: none;
  outline: none;
  color: rgb(34, 97, 159);
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: "Cairo";
}
.round {
  position: absolute;
  top: -10px;
  left: -10px;
  color: #ffffff;
  width: 40px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Cairo";
  border: 3px solid #eef3f3;
  text-align: center;
  border-radius: 10%;
  background: rgb(86, 178, 191);
  display: flex;
  align-items: center;
  justify-content: center;
}
#search {
  width: 25%;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #c3bebe;
  padding: 5px;
  color: gray;
}
#searchdiv {
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#circle_div {
  background-color: #fff;
  width: 50%;
  height: 350px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  box-shadow: 0px 0px 4px 0px #c7c4c4;
  border-radius: 5px;
  margin: 20px;
}
#min_circle {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
thead {
  background-color: #e7e8e8;
}
#pass_use:hover {
  cursor: pointer;
  transform: scale(1.2);
}
#new_itemnav {
  height: 40px;
  font-size: 18px;
  font-family: "Cairo";
  background-color: #132a3b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
#dailog {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 60px;
  font-weight: 600;
  font-family: "Cairo";
  font-size: 18px;
  width: 100%;
}
@media screen and (min-width: 900px) {
  #col_dash {
    width: 33.33%;
    padding: 10px;
    margin-bottom: 1%;
  }
}

@media screen and (max-width: 950px) {
  #col_dash {
    width: 250px;
    padding: 10px;
    margin-bottom: 1%;
  }
  .Sign_container {
    width: 50%;
  }
}

@media screen and (max-width: 700px) {
  #col_dash {
    width: 80%;
    padding: 10px;
    margin-bottom: 1%;
  }
  #min_circle {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #dash_row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #circle_div {
    width: 90%;
    height: 400px;
  }
  .Sign_container {
    width: 90%;
  }
}

@media screen and (max-width: 425px) {
  #min_circle {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #col_dash {
    width: 250px;
    padding: 10px;
    margin-bottom: 1%;
  }
  .react-checkbox-tree{
    height: 200px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:#ec6323; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#ec6323; 
}