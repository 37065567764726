.MuiDataGrid-columnHeaderTitle 
{ font-weight: bold !important;
  overflow: visible !important;
  
  color: white !important; 
  font-size: 1rem ;
    
}

.MuiDataGrid-columnHeaders
{
    background-color:#132a3b !important;
}
.MuiTablePagination-displayedRows
{
    margin-top: auto;
}
.MuiTablePagination-root .MuiTablePagination-selectLabel
{
  margin-top: auto;
}


