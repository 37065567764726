
.containerRedirectPage{

  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.containerRedirectPage #notfound {
    position: relative;
    height: 100vh;
    background-color: #fafbfd;
  }
  
  .containerRedirectPage  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .containerRedirectPage  .notfound {
    max-width: 520px;
    width: 100%;
    text-align: center;
  }
  
  .containerRedirectPage  .notfound .notfound-bg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: -1;
  }
  
  .containerRedirectPage  .notfound .notfound-bg > div {
    width: 100%;
    background: #fff;
    border-radius: 90px;
    height: 125px;
  }
  
  .containerRedirectPage  .notfound .notfound-bg > div:nth-child(1) {
    -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
            box-shadow: 5px 5px 0px 0px #f3f3f3;
  }
  
  .containerRedirectPage  .notfound .notfound-bg > div:nth-child(2) {
    -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
            box-shadow: 5px 5px 0px 0px #f3f3f3;
    position: relative;
    z-index: 10;
  }
  
  .containerRedirectPage  .notfound .notfound-bg > div:nth-child(3) {
    -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
            box-shadow: 5px 5px 0px 0px #f3f3f3;
    position: relative;
    z-index: 90;
  }
  
  .containerRedirectPage  .notfound h1 {
    font-family: 'Quicksand', sans-serif;
    font-size: 86px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 8px;
    color: #151515;
  }
  
  .containerRedirectPage  .notfound h2 {
    font-family: 'Quicksand', sans-serif;
    font-size: 26px;
    margin: 0;
    font-weight: 700;
    color: #151515;
  }
  
  .containerRedirectPage  .notfound a {
    /* font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    background: #000e06;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 5px;
    color: #fff;
    font-weight: 700;
    margin-top: 20px; */
  }
  
  .containerRedirectPage  .notfound-social {
    margin-top: 20px;
  }
  
  .containerRedirectPage  .notfound-social>a {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    width: 40px;
    font-size: 14px;
    color: #fff;
    background-color: #dedede;
    margin: 3px;
    padding: 0px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
  }
  .containerRedirectPage  .notfound-social>a:hover {
    background-color: #18e06f;
  }
  
  @media only screen and (max-width: 767px) {
      .notfound .notfound-bg {
        width: 90%;
        margin: auto;
      }
  
      .notfound .notfound-bg > div {
        height: 85px;
     }

     .notfound h1 {
      font-size: 40px;
    }
  
    .notfound h2 {
      font-size: 16px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .notfound h1 {
      font-size: 68px;
    }
  
    .notfound h2 {
      font-size: 18px;
    }
  }
  